<template>
  <div style="padding:20px">
    <p style="margin-bottom: 20px">{{ $t('Custom fields') }}</p>

    <v-form ref="form">
      <DynamicFormContent
        style="padding: 0"
        :fields="fields"
      ></DynamicFormContent>
    </v-form>

    <v-btn
      :loading="loading"
      color="primary"
      type="submit"
      block
      raised
      large
      outlined
      @click="$emit('generate-preview')"
    >
      <span>
        {{ $t('Generate preview') }}
      </span>
    </v-btn>
  </div>
</template>

<style scoped>
  .progress-view {
    display: block ruby;
  }

  .progress-bar {
    width: 70%;
    margin: 0 10px 0 10px;
  }
</style>

<script>

import DynamicFormContent from '@/components/DynamicFormContent'

export default {
  name: 'TemplateConfig',

  components: {
    DynamicFormContent,
  },

  props: {
    fields: {
      type: Array
    },

    loading: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    validateForm() {
      return this.$refs.form.validate()
    }
  }
}

</script>

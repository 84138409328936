<template>
  <div>
    <v-row v-if="templates.length > 0">
      <v-col class="pt-5">
        <h4 v-if="type == 'html'">{{ $t('HTML Templates') }}</h4>
        <h4 v-if="type == 'static'">{{ $t('Static Templates') }}</h4>
        <h4 v-if="type == 'video'">{{ $t('Video Templates') }}</h4>
      </v-col>

      <v-col>
        <v-btn
          color="gray"
          class="float-right"
          v-if="selectedTemplates.length == 0"
          disabled
        >
          {{ $t('Export') }}
          <v-icon>mdi-menu-down</v-icon>
        </v-btn>

        <v-menu
          offset-y
          v-if="selectedTemplates.length > 0"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
              class="float-right"
            >
              {{ $t('Export') }}
              <span v-if="selectedTemplates.length > 0">{{ selectedTemplates.length }}</span>
              <span v-if="selectedTemplates.length == 1">{{ $t('asset') }}</span>
              <span v-if="selectedTemplates.length > 1">{{ $t('assets') }}</span>
              <v-icon>mdi-menu-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in selectedTemplatesExportList"
              :key="index"
              @click="raster(item)"
            >
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>

    <v-row>
      <v-card
        v-for="(template, idx) in templates"
        :key="idx"
        :width="template.width + 34"
        :height="template.height + 84"
        elevation="5"
        outlined
        @click="$emit('toggleSelectedTemplate', template)"
        class="ma-2"
      >
        <v-card-title>
          <v-icon
            :color="selectedNodeIds.includes(template.node_id) ? 'primary' : 'gray'"
          >mdi-check-circle</v-icon>
          <span
            :style="'margin-left:10px;width:' + (template.width - 70) + 'px'"
            class="text-truncate text-subtitle-1 font-weight-medium"
          >{{ template.title }}</span>
          <v-btn
            @click.stop="reloadIframe(idx, template.url)"
            icon
          >
            <v-icon
              color="gray"
            >mdi-reload</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <iframe
            :ref="'iframe_' + idx"
            :width="template.width"
            :height="template.height"
            frameborder="0"
            :src="template.url"
          ></iframe>
        </v-card-text>
      </v-card>
    </v-row>
  </div>
</template>

<script>

import { mapActions } from 'vuex'
import AppActions from '@/store/app/actions-types'
import BackgroundActions from '@/store/background_actions/actions-types.js'
import SelfServiceTemplateActions from '@/store/content/self_service_template/actions-types'
import { BgActionEventBus } from '@/bgActionEventBus'

export default {
  name: 'TemplateExport',

  props: {
    type: {
      type: String
    },

    templates: {
      type: Array
    },

    selectedNodeIds: {
      type: Array,
      default: function() {
        return []
      }
    },

    dataProvider: Object,

    jstNodeId: [Number, String],

    jstConfig: Object,
  },

  data() {
    return {
      menuItems: [
        {
          type: 'original',
          title: 'Same as original'
        },
        {
          type: 'image',
          title: 'Convert to image'
        },
        {
          type: 'video',
          title: 'Convert to video'
        },
      ]
    }
  },

  computed: {
    selectedTemplates() {
      return this.templates.filter(item => this.selectedNodeIds.includes(item.node_id))
    },

    selectedTemplatesExportList() {
      const templatesPath = this.selectedTemplates.map(template => template.template_path)

      const templatesPostProcessings = this.jstConfig.config.FilesToRender
        .filter(template => templatesPath.includes(template.template_path))
        .reduce((array, template) => {
          return array.filter(x => template.post_processings.includes(x))
        }, this.menuItems.map(item => item.type))

      return this.menuItems.filter(item => templatesPostProcessings.includes(item.type))
    },
  },

  methods: {
    ...mapActions('app', [
      AppActions.OPEN_APP_INFO_MESSAGE,
      AppActions.OPEN_APP_ERROR_MESSAGE,
      AppActions.OPEN_APP_SUCCESS_MESSAGE
    ]),

    ...mapActions('content/self_service_template', [
      SelfServiceTemplateActions.POST_RASTER_TEMPLATES
    ]),

    ...mapActions('background_actions', [
      BackgroundActions.CREATE_BACKGROUND_ACTION
    ]),

    raster(exportItem) {
      const data = {
        templates: this.selectedTemplates.map(template => template.template_path),
        raster_type: exportItem.type,
        renders_node_ids: this.selectedTemplates.map(template => template.node_id),
        data_provider: this.dataProvider
      }

      this[SelfServiceTemplateActions.POST_RASTER_TEMPLATES]({nodeId: this.jstNodeId, data: data})
        .then(() => {
          this[AppActions.OPEN_APP_INFO_MESSAGE](this.$t(`starting_to_export_template`))
          BgActionEventBus.$on('message', (message) => {
            if (message.type == 'raster' && message.status == 'success') {
              this.$emit('templatesExported')
              BgActionEventBus.$off('message')
            }
          })
        })
        .catch(() => {
          this[AppActions.OPEN_APP_ERROR_MESSAGE](this.$t(`unable_to_export_template`))
        })
    },

    reloadIframe(idx, url) {
      this.$refs['iframe_' + idx][0].src = 'about:blank'
      this.$refs['iframe_' + idx][0].src = url
    },
  }
}

</script>

<template>
  <div style="padding:10px">

    <v-list-item-group
      v-model="selectedGeneration"
      active-class="primary--text elevation-6"
      color="primary"
    >
      <v-list-item
        v-for="generationId, index in descIds"
        :key="index"
        @click.prevent.stop="$emit('historySelected', generationId); selectedGeneration = index"
        class="rounded-lg elevation-3 mb-5 white"
      >
        <v-list-item-content class="ma-0">
          <v-list-item-subtitle>
            GENERATED ON
          </v-list-item-subtitle>

          <div class="pt-2 text-truncate caption">
            {{ convertDate(generationId) }}
          </div>
        </v-list-item-content>

        <v-list-item-avatar>
          <v-menu
            offset-y
            v-if="hasFileToDownload(generationId)"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon size="28">mdi-download</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(nodeId, index) in getTemplateOutputs(generationId)"
                :key="index"
                @click="downloadTemplateFile(nodeId)"
              >
                <v-list-item-title>{{ $t('template_output_download_' + index) }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-list-item-avatar>
      </v-list-item>
    </v-list-item-group>

  </div>
</template>

<script>

import { mapActions } from 'vuex'
import ContentActions from '@/store/content/actions/actions-types'
import HumanReadable from "@/utils/human_readable";

export default {
  name: 'TemplateHistorical',

  props: {
    history: {
      type: Object
    },

    maxWidth: {
      type: Number,
      default: 298
    }
  },

  data() {
    return {
      selectedGeneration: null
    }
  },

  computed: {
    descIds() {
      return Object.keys(this.history).reverse()
    }
  },

  methods: {
    ...mapActions('content/actions', [ContentActions.DOWNLOAD_FILE]),

    convertDate(date) {
      return HumanReadable.epochTimestampToDate(parseInt(date), true)
    },

    hasFileToDownload(generationId) {
      let has = false

      this.history[generationId].forEach(item => {
        if (!item.system_fields) {
          return
        }

        Object.keys(item.system_fields).forEach(key => {
          if (key.startsWith('template.output.')) {
            has = true
            return
          }
        })
      })

      return has
    },

    getTemplateOutputs(generationId) {
      const downloads = {}

      this.history[generationId].forEach(item => {
        if (item.system_fields && "template.output.original" in item.system_fields) {
          downloads.original = item.system_fields['template.output.original']
        }

        if (item.system_fields && "template.output.image" in item.system_fields) {
          downloads.image = item.system_fields['template.output.image']
        }

        if (item.system_fields && "template.output.video" in item.system_fields) {
          downloads.video = item.system_fields['template.output.video']
        }
      })

      return downloads
    },

    downloadTemplateFile(nodeId) {
      this[ContentActions.DOWNLOAD_FILE]({nodeId: nodeId, control: this})
    },

    updateSelectedGeneration(listIndex) {
      this.selectedGeneration = listIndex
    },
  }
}

</script>

<template>
  <div v-if="templates.length > 0">
    <v-row>
      <v-col class="pt-5">
        <h4 v-if="type == 'html'">{{ $t('HTML Templates') }} <span class="font-italic">(Preview)</span></h4>
        <h4 v-if="type == 'static'">{{ $t('Static Templates') }} <span class="font-italic">(Preview)</span></h4>
        <h4 v-if="type == 'video'">{{ $t('Video Templates') }} <span class="font-italic">(Preview)</span></h4>
      </v-col>
    </v-row>

    <v-row>
      <v-card
        v-for="(template, idx) in templates"
        :key="idx"
        :width="template.width + 34"
        :height="template.height + 84"
        class="ma-2"
        elevation="5"
        outlined
      >
        <v-card-title>
          <v-icon>mdi-image</v-icon>
          <span
            :style="'margin-left:10px;width:' + (template.width - 70) + 'px'"
            class="text-truncate text-subtitle-1 font-weight-medium"
          >{{ template.title }}</span>
        </v-card-title>

        <v-card-text>
          <v-img
            :src="templateCoversByPath[template.template_path]"
            :width="template.width"
            :height="template.height"
            class="img-bg"
          >
            <template v-slot:placeholder>
              <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
              >
                <v-icon
                  size="150"
                >mdi-file-image</v-icon>
              </v-row>
            </template>
          </v-img>
        </v-card-text>
      </v-card>
    </v-row>
  </div>
</template>

<style scoped>
  .img-bg {
    background-color: #DADCE0;
  }
</style>

<script>
  export default {
    name: 'TemplateCovers',

    props: {
      type: {
        type: String
      },

      templates: Array,

      templateCovers: Array,
    },

    computed: {
      templateCoversByPath() {
        let coverByPath = []

        this.templateCovers.forEach(element => {
          coverByPath[element.template_path] = element.url
        });

        return coverByPath
      }
    },
  }
</script>
